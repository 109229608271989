import * as React from "react";
import { Link } from "gatsby";
import Form from "./form";
import ContactList from "./contactlist";

const Footer = () => {
  return (
    <footer className="static bottom-0 w-full flex flex-row justify-between items-center bg-richblack bg-opacity-60 z-10 backdrop-blur-md rounded-tr-xl rounded-tl-xl p-10">
      <section>
        <ContactList></ContactList>
      </section>
      <section>
        <a
          href="https://www.termsandconditionsgenerator.com/live.php?token=wi9WQ87J37d0jwb6HDtuqAPoZD2pqNzD"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </a>
      </section>
      <section>
        <Form></Form>
      </section>
    </footer>
  );
};

export default Footer;
