import * as React from "react";
import { BsInfoLg } from "react-icons/bs";
import { ImProfile } from "react-icons/im";
import { TbWriting } from "react-icons/tb";
import { GrWorkshop } from "react-icons/gr";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityAuthor {
        nodes {
          _rawBio
          position
          image {
            alt
            asset {
              gatsbyImageData
            }
          }
          name
          slug {
            current
          }
        }
      }
    }
  `);
  const authors = data.allSanityAuthor.nodes;
  let pathEditor, pathAdmin;
  return (
    <div className="flex flex-col justify-center items-center px-72 py-20 gap-8">
      <h1 className="text-5xl text-richblack font-extrabold underline flex flex-row gap-2 items-center">
        <BsInfoLg></BsInfoLg>
        Who are we?
      </h1>
      <p className="text-2xl text-richblack text-justify leading-loose tracking-wide">
        Dragonfly post is a content website that strives to provide readers with
        interesting and engaging articles to start their day off right. We
        believe that there's nothing better than reading a good article in the
        morning, and we're dedicated to curating a selection of the best content
        out there.We started in 2014 with a mission to make reading fun again,
        and we haven't stopped since! Dragonfly post is your go-to source for
        feel-good content, and we guarantee that you'll always leave our website
        feeling happier than when you came in.
      </p>
      <h1 className="text-5xl text-richblack font-extrabold underline flex flex-row gap-2 items-center">
        <ImProfile></ImProfile>
        Meet the team!
      </h1>
      <section className="flex flex-col justify-center items-center gap-6">
        <h1 className="text-3xl text-richblack font-extrabold underline flex flex-row gap-2 items-center">
          <GrWorkshop></GrWorkshop>Administration
        </h1>
        <span className="flex flex-row justify-center items-center gap-4">
          {authors.map((node: any) => {
            pathAdmin = `/admin/${node.slug.current}`;
            if (node.position != "Editor") {
              return (
                <Link to={pathAdmin} className="group">
                  <article className="flex flex-col justify-center items-center gap-4 rounded-xl p-10 transition-all duration-500 group-hover:bg-richblack">
                    <GatsbyImage
                      image={node.image.asset.gatsbyImageData}
                      alt={node.image.alt}
                      className="w-40 rounded-full"
                    ></GatsbyImage>
                    <h1 className="text-4xl text-richblack font-extrabold transition-all duration-500 group-hover:text-platinum underline">
                      {node.name}
                    </h1>
                    <h2 className="text-shadowblue transition-all duration-500 group-hover:text-bdazzledblue">
                      {node.position}
                    </h2>
                    <span
                      className="[&>p]:px-36 [&>p]:text-justify [&>p]:text-richblack [&>p]:font-medium [&>p]:tracking-wide [&>p]:text-2xl
              [&>p]:transition-all [&>p]:duration-500 [&>p]:group-hover:text-platinum"
                    ></span>
                  </article>
                </Link>
              );
            }
          })}
        </span>
        <h1 className="text-3xl text-richblack font-extrabold underline flex flex-row gap-2 items-center">
          <TbWriting></TbWriting>Editors
        </h1>
        <span className="flex flex-row justify-center items-center gap-4">
          {authors.map((node: any) => {
            pathEditor = `/editor/${node.slug.current}`;
            if (node.position === "Editor") {
              return (
                <Link to={pathEditor} className="group">
                  <article className="flex flex-col justify-center items-center gap-4 rounded-xl p-10 transition-all duration-500 group-hover:bg-richblack">
                    <GatsbyImage
                      image={node.image.asset.gatsbyImageData}
                      alt={node.image.alt}
                      className="w-40 rounded-full"
                    ></GatsbyImage>
                    <h1 className="text-4xl text-richblack font-extrabold transition-all duration-500 group-hover:text-platinum underline">
                      {node.name}
                    </h1>
                    <h2 className="text-shadowblue transition-all duration-500 group-hover:text-bdazzledblue">
                      {node.position}
                    </h2>
                    <span
                      className="[&>p]:px-36 [&>p]:text-justify [&>p]:text-richblack [&>p]:font-medium [&>p]:tracking-wide [&>p]:text-2xl
              [&>p]:transition-all [&>p]:duration-500 [&>p]:group-hover:text-platinum"
                    ></span>
                  </article>
                </Link>
              );
            }
          })}
        </span>
      </section>
    </div>
  );
};

export default About;
