import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { PortableText } from "@portabletext/react";
import RandomStuff from "./randomstuff";

const LatestPost = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityPost(sort: { fields: publishedAt, order: DESC }) {
        nodes {
          publishedAt(formatString: "DD.MM.YYYY HH:mm")
          _rawExcerpt
          title
          slug {
            current
          }
          mainImage {
            asset {
              gatsbyImageData
            }
            alt
          }
          categories {
            title
            slug {
              current
            }
          }
          authors {
            author {
              name
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);
  const newestpost = data.allSanityPost.nodes[0];
  const pathPost = `/posts/${newestpost.slug.current}`;
  const pathCategory = `/tags/${newestpost.categories[0].slug.current}`;
  const pathAuthor = `/editor/${newestpost.authors[0].author.slug.current}`;
  return (
    <div className="h-screen bg-richblack flex flex-col justify-center gap-6 items-center">
      <div className="flex flex-col gap-2 justify-center items-center">
        <h1 className="text-platinum text-5xl font-black tracking-wide">
          The Dragonfly Post
        </h1>
        <h2 className="text-platinum font-bold uppercase tracking-widest">
          The truth is out there, just not here.
        </h2>
      </div>
      <StaticImage
        src="../images/onlyicon-platinum.png"
        alt="logo"
        className="w-24"
        placeholder="tracedSVG"
        loading="eager"
      ></StaticImage>
      <Link to={pathPost} className="group">
        <article className="flex flex-row gap-2 text-platinum rounded-md transition-all duration-500 group-hover:bg-platinum p-10">
          <section className="flex flex-col justify-between gap-4">
            <Link to={pathCategory}>
              <h2 className="text-left text-bdazzledblue">
                Latest News - {newestpost.categories[0].title}
              </h2>
            </Link>
            <span className="flex flex-col gap-2 text-platinum transition-all duration-500 group-hover:text-richblack">
              <h1 className="text-4xl font-black">{newestpost.title}</h1>
              <PortableText value={newestpost._rawExcerpt}></PortableText>
            </span>
            <Link to={pathAuthor}>
              <h3 className="text-left text-bdazzledblue">
                Written by {newestpost.authors[0].author.name}, at{" "}
                {newestpost.publishedAt}
              </h3>
            </Link>
          </section>
          <GatsbyImage
            image={newestpost.mainImage.asset.gatsbyImageData}
            alt={newestpost.mainImage.alt}
            className="w-44 rounded-md"
            loading="eager"
          ></GatsbyImage>
        </article>
      </Link>
      <RandomStuff></RandomStuff>
    </div>
  );
};

export default LatestPost;
