import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { BiNews } from "react-icons/bi";
import { AiOutlineExclamation } from "react-icons/ai";
import { FiTrendingUp } from "react-icons/fi";
import PostBlock from "./postblock";

const PostList = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityCategory {
        nodes {
          title
          description
          slug {
            current
          }
        }
      }
      allSanityPost(sort: { fields: publishedAt, order: DESC }) {
        totalCount
        nodes {
          authors {
            author {
              slug {
                current
              }
              name
            }
          }
          categories {
            title
            slug {
              current
            }
          }
          slug {
            current
          }
          title
          publishedAt(formatString: "DD.MM.YYYY HH:mm")
          mainImage {
            asset {
              gatsbyImageData
            }
            alt
          }
          _rawExcerpt
        }
      }
    }
  `);
  const Posts = data.allSanityPost.nodes;
  const Categories = data.allSanityCategory.nodes;
  let max4 = 0;
  return (
    <div className="flex flex-col justify-center items-center gap-20 py-20">
      <h1 className="text-6xl text-richblack font-black underline flex flex-row gap-2 items-center hover:cursor-grab focus:cursor-grabbing">
        <FiTrendingUp></FiTrendingUp>
        Life's too short to read old news
        <FiTrendingUp></FiTrendingUp>
      </h1>
      <div>
        {Categories.map((nodeCat: any) => {
          max4 = 0;
          return (
            <section className="px-12 my-8">
              <Link to={`/tags/${nodeCat.slug.current}`} className="group">
                <h1
                  className="flex flex-row gap-1 items-center text-3xl text-richblack font-extrabold underline"
                  id={nodeCat.title}
                >
                  <BiNews className="absolute transition-all duration-300 group-hover:opacity-0"></BiNews>
                  <AiOutlineExclamation className="opacity-0 transition-all duration-500 group-hover:opacity-100"></AiOutlineExclamation>{" "}
                  New in - {nodeCat.title}
                </h1>
              </Link>
              <p className="text-2xl text-richblack font-medium">
                {nodeCat.description}
              </p>
              <ul className="flex gap-4 flex-wrap justify-center items-center py-4">
                {Posts.map((node: any) => {
                  if (node.categories[0].title === nodeCat.title && max4 <= 4) {
                    max4++;
                    return (
                      <li className="flex-grow basis-[50rem]">
                        <PostBlock node={node}></PostBlock>
                      </li>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </ul>
            </section>
          );
        })}
      </div>
    </div>
  );
};

export default PostList;
