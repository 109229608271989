import * as React from "react";
import { useRef } from "react";
import { TbMath, TbMathFunctionOff } from "react-icons/tb";
import { SiAzuredevops } from "react-icons/si";
import { BiPhoneCall } from "react-icons/bi";

const RandomStuff = () => {
  const form = useRef(null);
  const sizeoficons = 28;
  const onClickWeird = (event: any) => {
    alert("Settings have been applied!");
    event.preventDefault();
    event.target.reset();
  };
  return (
    <div className="flex flex-col gap-4 justify-center items-center border-t-2 border-t-platinum pt-4">
      <h1 className="text-3xl text-platinum font-bold">Website Settings</h1>
      <form
        className="fill-platinum flex flex-row gap-8"
        onSubmit={onClickWeird}
        ref={form}
      >
        <span className="flex flex-col items-center justify-center gap-4">
          <input type="checkbox" />
          <TbMath color="#E0E1DD" size={sizeoficons}></TbMath>
        </span>
        <span className="flex flex-col items-center justify-center gap-4">
          <input type="checkbox" />
          <TbMathFunctionOff
            color="#E0E1DD"
            size={sizeoficons}
          ></TbMathFunctionOff>
        </span>
        <span className="flex flex-col items-center justify-center gap-4">
          <input type="checkbox" />
          <SiAzuredevops color="#E0E1DD" size={sizeoficons}></SiAzuredevops>
        </span>
        <span className="flex flex-col items-center justify-center gap-4">
          <input type="checkbox" />
          <BiPhoneCall color="#E0E1DD" size={sizeoficons}></BiPhoneCall>
        </span>
        <input
          type="submit"
          className="text-platinum font-semibold hover:cursor-pointer"
          value="Submit"
        ></input>
      </form>
    </div>
  );
};

export default RandomStuff;
