import * as React from "react";
import { HeadFC } from "gatsby";
import Layout from "../components/layout";
import PostList from "../components/postlist";
import LatestPost from "../components/latestpost";
import About from "../components/about";
import Footer from "../components/footer";

const IndexPage = () => {
  return (
    <main className="">
      <LatestPost></LatestPost>
      <Layout></Layout>
      <PostList></PostList>
      <About></About>
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Home</title>;
