import * as React from "react";
import {
  SlSocialInstagram,
  SlSocialTwitter,
  SlSocialFacebook,
} from "react-icons/sl";
import { SiTiktok } from "react-icons/si";

const ContactList = () => {
  const sizeoficon = 28;
  return (
    <ul className="flex flex-row gap-2">
      <li>
        <SlSocialInstagram size={sizeoficon}></SlSocialInstagram>
      </li>
      <li>
        <SiTiktok size={sizeoficon}></SiTiktok>
      </li>
      <li>
        <SlSocialTwitter size={sizeoficon}></SlSocialTwitter>
      </li>
      <li>
        <SlSocialFacebook size={sizeoficon}></SlSocialFacebook>
      </li>
    </ul>
  );
};

export default ContactList;
